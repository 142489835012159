export enum AdvertisementPosition {
  HOME = 'home',
  HOME_GUEST = 'home_guest',
  ACCOUNT_SETTING = 'account_setting',
  VIDEO = 'video',
}

export interface Advertisement {
  id: number
  name: string
  position: AdvertisementPosition
  image: string
  image_link: string
  link: string
  mobile_image: string
  mobile_image_link: string
  show: number
  created_at: string
  updated_at: string
}

export type PartialAdvertisement = Partial<Advertisement>;
